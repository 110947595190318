<template>
  <el-dialog
    top="12vh"
    width="580px"
    visible
    append-to-body
    custom-class="rounded"
    :show-close="false"
    :before-close="close"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            <h2>
              {{ $t('supplierAccountDialog.tabs.paymentMethod.addPaymentMethodModal.title') }}
            </h2>
            <p v-if="supplierName">
              {{ supplierName }}
            </p>
          </span>
          <span>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="close">
              <CloseIcon />
            </Button>
          </span>
        </div>
      </div>
    </template>
    <div class="p-4">
      <el-form ref="form" :model="formModel" :show-message="false" class="mb-4">
        <el-form-item prop="bankAccount.accountName" class="m-0" required>
          <small class="text-muted">
            {{ $t('supplierAccountDialog.tabs.paymentMethod.bankAccountName') }}
          </small>
          <el-input v-model="formModel.bankAccount.accountName" />
        </el-form-item>
        <div class="row g-0">
          <el-form-item
            v-if="countryCode === 'IL'"
            prop="bankAccount.bankNumber"
            class="col-7 m-0"
            :class="$t('direction') === 'ltr' ? 'pe-4' : 'ps-4'"
            :error="showChecksumValidationError ? 'invalid' : null"
            required
          >
            <small class="text-muted">
              {{ $t('supplierAccountDialog.tabs.paymentMethod.bank') }}
            </small>
            <el-select v-model="formModel.bankAccount.bankNumber" filterable class="w-100">
              <el-option
                v-for="(bankName, bankNumber) in supportedBanks"
                :key="bankNumber"
                :value="bankNumber"
                :label="`${bankNumber} - ${bankName}`"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="countryCode === 'IL'"
            prop="bankAccount.branchNumber"
            class="col-2 m-0"
            :class="$t('direction') === 'ltr' ? 'pe-4' : 'ps-4'"
            :error="showChecksumValidationError ? 'invalid' : null"
            required
          >
            <small class="text-muted">
              {{ $t('supplierAccountDialog.tabs.paymentMethod.bankBranchNumber') }}
            </small>
            <el-input v-model="formModel.bankAccount.branchNumber" type="number" />
          </el-form-item>
          <el-form-item
            v-if="countryCode === 'US'"
            prop="bankAccount.routingNumber"
            :class="$t('direction') === 'ltr' ? 'pe-4 col-6 m-0' : 'ps-4 col-6 m-0'"
            :error="showChecksumValidationError ? 'invalid' : null"
            required
          >
            <small class="text-muted">
              {{ $t('supplierAccountDialog.tabs.paymentMethod.routingNumber') }}
            </small>
            <el-input v-model="formModel.bankAccount.routingNumber" type="number" />
          </el-form-item>
          <el-form-item
            prop="bankAccount.accountNumber"
            :class="countryCode === 'IL' ? 'col-3 m-0' : 'col-6 m-0'"
            :error="showChecksumValidationError ? 'invalid' : null"
            required
          >
            <small class="text-muted">
              {{ $t('supplierAccountDialog.tabs.paymentMethod.bankAccountNumber') }}
            </small>
            <el-input v-model="formModel.bankAccount.accountNumber" type="number" />
          </el-form-item>
          <div v-if="showChecksumValidationError" class="mt-2">
            <div class="d-flex align-items-center">
              <ErrorFullIcon />
              <p class="red me-2">{{ $t('payment.addBankAccountModal.masavValidationFailed') }}</p>
            </div>
          </div>
        </div>
      </el-form>
      <div class="d-flex justify-content-end">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleSavePaymentMethod">
          {{ $t('commons.save') }}
        </Button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue';

import { Button } from '@/modules/core';
import { CloseIcon, ErrorFullIcon } from '@/assets/icons';
import { useSupportedBanks, validateAccountChecksum } from '@/modules/payment/compositions/bankAccount';
import { useTenancy } from '@/modules/auth';
export default {
  components: { Button, CloseIcon, ErrorFullIcon },
  props: {
    initialPaymentMethod: { type: Object, default: () => null },
    supplierName: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const countryCode = computed(() => currentTenant.value?.countryCode);
    const businessId = computed(() => currentTenant.value?.id);

    const { supportedBanks } = useSupportedBanks(countryCode);
    return {
      businessId,
      countryCode,
      supportedBanks,
      validateAccountChecksum,
      showChecksumValidationError: ref(false),
    };
  },
  data() {
    return {
      formModel: {
        paymentMethod: 'bankTransfer',
        bankAccount: {
          accountName: '',
          accountNumber: '',
          ...(this.countryCode === 'IL' && { bankNumber: '' }),
          ...(this.countryCode === 'IL' && { branchNumber: '' }),
          ...(this.countryCode === 'US' && { routingNumber: '' }),
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async validateChecksumAndSave() {
      const emitUpdate = () => {
        this.$emit('updatePaymentMethod', this.formModel);
        this.close();
      };

      const validationResult = await validateAccountChecksum({
        ...this.formModel.bankAccount,
        businessId: this.businessId,
      });
      if (validationResult) {
        emitUpdate();
      } else {
        this.showChecksumValidationError = true;
      }
    },
    handleSavePaymentMethod() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.validateChecksumAndSave();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .el-dialog__body,
::v-deep .el-dialog__header {
  padding: 0;
}
.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.red {
  color: #e52044;
}
</style>
