<template>
  <el-dialog :visible.sync="dialogVisible" width="40%" :append-to-body="true" @close="onClose">
    <span slot="title" class="el-dialog__title">
      <template v-if="itemId">
        Update Item
        <small v-if="lastPurchased" class="text-muted">
          (last purchased on {{ lastPurchased.event.date | moFormatDate('L') }} by {{ lastPurchased.restaurant.name }}
          <a :href="lastPurchased.documentUrl" target="_blank"><i class="el-icon-document"></i></a>)
        </small>
      </template>
      <template v-else>Add Item</template>
    </span>
    <el-form v-if="dialogVisible" ref="form" :model="model" :rules="rules" label-width="150px">
      <el-form-item prop="name">
        <p class="pb-3">Name</p>
        <el-input v-model="model.name"></el-input>
        <div v-if="showSimilarItems" class="similar-items-modal">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Item</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="{ item, score } in similarItems" :key="item._id">
                <td>{{ displayItemName(item) }}</td>
                <td>{{ score.toFixed(3) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form-item>

      <div class="form-row">
        <div class="col">
          <el-form-item prop="code">
            <p class="pb-3">SKU</p>
            <el-input v-model="model.code" class="direction-ltr"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item prop="manufacturerCode">
        <p class="pb-3">Manufacturer Code</p>
        <el-input v-model="model.manufacturerCode"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm">
        <template v-if="itemId">Update</template>
        <template v-else>Add</template>
      </el-button>
    </span>
  </el-dialog>
</template>

<script type="text/javascript">
import Fuse from 'fuse.js';
import { useProductsNew } from '@/modules/product/compositions/products';
import { insertItem, lastPurchased, updateItem } from '../compositions/suppliers-oprations';
import { ItemSchema } from '../models/item';
import { computed, ref } from 'vue';
const EMPTY_MODEL = {
  name: '',
  code: '',
  manufacturerCode: '',
};

export default {
  props: ['visible', 'supplierId', 'itemId', 'defaults'],
  setup(props) {
    const model = ref({ ...EMPTY_MODEL });
    const { products } = useProductsNew(computed(() => props.supplierId));

    const itemStore = computed(() => {
      const fuseOptions = {
        shouldSort: true,
        includeScore: true,
        threshold: 0.2,
        keys: ['name'],
      };
      return new Fuse(products.value, fuseOptions);
    });

    const similarItems = computed(() => {
      if (model.value.name.length < 3) return [];
      if (!itemStore.value) return [];
      return itemStore.value.search(model.value.name);
    });

    const showSimilarItems = computed(() => Boolean(similarItems.value.length));

    return {
      model,
      rules: {
        name: { required: true, message: 'Please enter name' },
      },
      lastPurchased: null,
      itemStore,
      similarItems,
      showSimilarItems,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  watch: {
    defaults: {
      handler(newVal) {
        Object.assign(this.model, EMPTY_MODEL, newVal);
      },
      deep: true,
      immediate: true,
    },
    itemId: {
      handler(newVal) {
        if (newVal) {
          lastPurchased({ itemId: newVal })
            .then((result) => {
              this.lastPurchased = result;
            })
            .catch((err) => {
              this.lastPurchased = null;
              this.$notify.error({ title: err.error, message: err.reason });
            });
        } else {
          this.lastPurchased = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.model = { ...EMPTY_MODEL };
      this.$emit('close');
    },
    displayItemName(item) {
      return item.code ? `${item.code} - ${item.name}` : item.name;
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.itemId) {
            this.itemId = await this.updateItem(this.itemId, { supplierId: this.supplierId, ...this.model });
          } else {
            await this.addItem({ supplierId: this.supplierId, ...this.model });
          }
          this.dialogVisible = false;
        }
      });
    },
    addItem(item) {
      ItemSchema.clean(item, { mutate: true });
      const loading = this.$loading();
      return insertItem(item)
        .then((item) => {
          const itemId = item._id;
          this.$message.success('Added item');
          this.$emit('submit', { _id: itemId, ...item });
          return itemId;
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        })
        .finally(() => loading.close());
    },
    updateItem(itemId, item) {
      const loading = this.$loading();
      return updateItem({ itemId, item })
        .then(() => {
          this.$message.success('Updated item');
          this.$emit('submit', { _id: itemId, ...item });
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        })
        .finally(() => loading.close());
    },
  },
  i18n: {
    messages: {
      en: {
        reasons: {
          notPurchasedRecently: 'not purchased recently',
          uomUknown: 'purchase unit of measure unknown',
          goodUknown: 'good unknown',
        },
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.similar-items-modal {
  top: 38px;
  width: max-content;
  left: calc(100% + 20px);
  position: absolute;
  background: #ffffff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}

::v-deep .direction-ltr .el-input__inner {
  direction: ltr;
}
</style>
