<template>
  <el-dialog :visible.sync="dialogVisible" width="40%" @close="onClose">
    <span slot="title" class="el-dialog__title">
      <template v-if="itemGroupId">Update Group</template>
      <template v-else>Add Group</template>
    </span>
    <el-form v-if="dialogVisible" ref="form" :model="model" :rules="rules" label-width="150px">
      <el-form-item label="Name" prop="name">
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item label="Items" prop="itemIds">
        <el-transfer
          v-model="model.itemIds"
          :data="items"
          :props="{ key: '_id', label: 'name' }"
          filterable
          :titles="['All Items', 'Group Items']"
          class="w-100"
        />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="submitForm">
        <template v-if="itemGroupId">Update</template>
        <template v-else>Add</template>
      </el-button>
    </span>
  </el-dialog>
</template>

<script type="text/javascript">
import { Items, ItemGroups } from '@/imports/api/collections';
import { insertItemGroup, updateItemGroup } from '../compositions/suppliers-oprations';

const EMPTY_MODEL = {
  name: '',
  itemIds: [],
};

export default {
  props: ['visible', 'itemGroupId', 'supplierId', 'defaults'],
  data() {
    return {
      model: { ...EMPTY_MODEL },
      rules: {
        name: { required: true, message: 'Please enter name' },
      },
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  watch: {
    defaults: {
      handler(newVal) {
        Object.assign(this.model, EMPTY_MODEL, newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.model = { ...EMPTY_MODEL };
      this.$emit('close');
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.itemGroupId) {
            this.updateItemGroup(this.itemGroupId, { supplierId: this.supplierId, ...this.model });
          } else {
            this.addItemGroup({ supplierId: this.supplierId, ...this.model });
          }
        }
      });
    },
    addItemGroup(group) {
      ItemGroups.simpleSchema().clean(group, { mutate: true });
      const loading = this.$loading();
      insertItemGroup(group)
        .then(() => {
          this.$message.success('Added group');
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        })
        .finally(() => loading.close());
    },
    updateItemGroup(groupId, group) {
      const loading = this.$loading();
      updateItemGroup({ itemGroupId: groupId, itemGroup: group })
        .then(() => {
          this.$message.success('Updated group');
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        })
        .finally(() => loading.close());
    },
  },
  meteor: {
    $subsribe: {
      'item.bySuppliers'() {
        return [this.supplierId];
      },
    },
    items() {
      return Items.find({ supplierId: this.supplierId });
    },
  },
};
</script>
